// @flow
import * as React from 'react';
import Container from '../components/grid/container';
import DefaultLayout from '../layouts/default';
import Column from '../components/grid/column';
import Row from '../components/grid/row';
import Link from '../components/link';

export default () => (
  <DefaultLayout slim>
    <Container>
      <Row spaced={2}>
        <Column>
          <h1>Thanks for Subscribing</h1>
          <p>
            Please check your inbox for a confirmation email in order to confirm your subscription.
          </p>
          <p>
            <Link button to="/">Go Home</Link>
          </p>
        </Column>
      </Row>
    </Container>
  </DefaultLayout>
);
